import React from "react";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {observable} from "mobx";
import userStore from "../../stores/userStore"
import config from "../../config/main.config";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import {createText, updateText} from "../../helper/util";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

@observer
class EditSimpleUpperTextComponent extends React.Component {
    @observable language = "de-DE"

    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore,
            editState: false,
            editValue: ""
        }
        if (typeof this.props.textStore[this.props.id] === "undefined") {
            fetchTextById(this.props.id).then(text => {
                if (typeof text !== "undefined") {
                    this.setState({...this.state, textStore: {[this.props.id]: text[this.props.id]}})
                }
            })
        }
    }

    handleChange(event, data) {
     this.setState({...this.state, editValue: event.target.value});
    }

    render() {
        let {id, style} = this.props;
        let textStore = this.state.textStore;
        // TODO: Make also to const this is only for test purpose set to let
        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let textToShow = typeof textStore[id] === "undefined" ? {content: ""} : textStore[id]["de-DE"];

        if (textToShow.content.indexOf('src="http://' + id) > -1) {
            textToShow = textToShow.content.replace('src="http://' + id, 'src="' + config.BASE_IMAGE_URL + id);
        }

        let contentToShow = <p>{textToShow.content.toUpperCase()}</p>;

        if (displayType === "admin") {
            if (this.state.editState) {
                if (style === null) {
                    style = {
                        "zIndex": 1,
                        "position": "relative",
                        "overflow": "auto"
                    };
                } else {
                    // need to copy this because otherwise I get "not extendable error"
                    let customStyle = Object.assign({}, style);
                    customStyle.zIndex = 1;
                    customStyle.position = "relative";

                    customStyle.overflow = "auto";
                    style = customStyle;
                }

                contentToShow = <div style={style}>
                    <Form>
                        <Form.Input value={this.state.editValue} onChange={this.handleChange.bind(this)}/>
                    </Form>
                    <br/>
                    <Button.Group>
                        <Button color="green" onClick={() => {
                            textToShow = {
                                content: this.state.editValue,
                                title: "",
                                description: ""
                            };

                            if (typeof textStore[id] === "undefined" || typeof textStore[id][this.language] === "undefined") {
                                createText(id, textToShow, this.language, this.props.namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(id).then(text => {
                                            textStore[id] = text[id];
                                            this.setState({...this.state, editState: false})
                                        })
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                });
                            } else {
                                updateText(id, textToShow, this.language, this.props.namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(id).then(text => {
                                            textStore[id] = text[id];
                                            this.setState({...this.state, editState: false})
                                        })
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                });
                            }
                        }}>Speichern</Button>
                        <Button color="red" onClick={() => {
                            this.setState({...this.state, editState: false})
                        }}>Abbrechen</Button>
                    </Button.Group>
                </div>
            } else {
                contentToShow = <div style={style}>
                    <p>{textToShow.content.toUpperCase()}</p>
                    {textToShow.isNamespace === this.props.namespace ? "" :
                        <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                               color={"red"}>!</Label>}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.setState({...this.state, editState: true, editValue: textToShow.content})
                    }}/>
                </div>
            }

        }
        return (
            contentToShow
        );
    }
}

EditSimpleUpperTextComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object
};

EditSimpleUpperTextComponent.defaultProps = {
    textStore: {},
    namespace: null,
    id: Math.random().toString(),
    style: null
};

export default EditSimpleUpperTextComponent