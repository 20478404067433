import React from "react";
import {observer} from "mobx-react";
import PropTypes from "prop-types";
import EditSimpleUpperTextComponent from "../moap-components/EditSimpleUpperTextComponent";
import userStore from "../../stores/userStore";
import {observable} from "mobx";
import config from "../../config/main.config";
import {createText, getTokenFromLocalStorage, updateText} from "../../helper/util";
import EditTextComponent from "../moap-components/EditTextComponent";
import {fetchTextById} from "../../../server/helper/ssrHelper";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";


@observer
class CardImageMetaTitleComponent extends React.Component {

    @observable editState = false;
    @observable editValue = "";

    constructor(props) {
        super(props);
        this.state = {
            textStore: this.props.textStore
        }
    }

    componentDidMount() {
        let ids = [this.props.metaText, this.props.nameText, this.props.imageLink];
        for (let i = 0; i < ids.length; i++) {
            if (typeof this.props.textStore[ids[i]] === "undefined") {
                fetchTextById(ids[i]).then(text => {
                    if (typeof text !== "undefined") {
                        this.setState(prevState => ({
                            ...prevState,
                            textStore: {...prevState.textStore, [ids[i]]: text[ids[i]]}
                        }));
                    }
                })
            }
        }
    }


    handleChange(event, data) {
        this.editValue = data.value;
        this.setState({});
    }

    uploadThumbnailImage(event) {
        let self = this;
        const data = new FormData();
        data.append('image', event.target.files[0]);

        fetch(config.BASE_URL + 'images/', {
            method: "POST",
            body: data,
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage()
            }
        }).then(function (response) {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    console.log("response json: " + json.toString());
                    self.editValue = json.link;
                });

            }
        }).catch((error) => {
            console.log(error)
        });
    }

    render() {
        const {metaText, nameText, imageLink} = this.props;
        let textStore = this.state.textStore;
        if (!metaText || !nameText || !imageLink) {
            console.log('Src is not acceptable');
            return null;
        }
        let textToShow = typeof textStore[imageLink] === "undefined" ? {content: ""} : textStore[imageLink]["de-DE"];
        let displayType = userStore.userFromServer !== null ? "admin" : "user";

        let editDiv = null;
        if (displayType === "admin") {
            if (this.editState) {
                editDiv = <div>
                    <Form>
                        <Form.Input value={this.editValue} onChange={this.handleChange.bind(this)}/>
                    </Form>
                    <br/>
                    <input type="file" id="file" className="inputfile"
                           onChange={this.uploadThumbnailImage.bind(this)}
                           accept="image/*"/>
                    <label htmlFor="file" className={"input-label"}>
                        Hochladen
                    </label>

                    <Button.Group>
                        <Button color="green" onClick={() => {
                            textToShow = {
                                content: this.editValue,
                                title: "",
                                description: ""
                            };
                            if (typeof textStore[imageLink] === "undefined" || typeof textStore[imageLink]["de-DE"] === "undefined") {
                                createText(imageLink, textToShow, "de-DE", this.props.namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(imageLink).then(text => {
                                            textStore[imageLink] = text[imageLink];
                                            this.editState = false;
                                            this.editValue = "";
                                            this.setState({});
                                        })
                                    }
                                }).catch(error => {
                                    console.log(error)
                                });
                            } else {
                                updateText(imageLink, textToShow, "de-DE", this.props.namespace).then(isSuccess => {
                                    if (isSuccess) {
                                        fetchTextById(imageLink).then(text => {
                                            textStore[imageLink] = text[imageLink];
                                            this.editState = false;
                                            this.editValue = "";
                                            this.setState({});
                                        })
                                    }
                                }).catch(error => {
                                    console.log(error)
                                });
                            }
                        }}>Speichern</Button>
                        <Button color="red" onClick={() => {
                            this.editState = false;
                            this.setState({});
                        }}>Abbrechen</Button>

                    </Button.Group>
                </div>
            } else {
                editDiv = <div>
                    {textToShow.content}
                    {textToShow.isNamespace === this.props.namespace ? "" :
                        <Label size={"tiny"} className={"namespace-note"} style={{opacity: "80%"}} floating
                               color={"red"}>!</Label>}
                    <br/>
                    <Button color="orange" icon="edit outline" onClick={() => {
                        this.editValue = textToShow.content;
                        this.editState = true
                        this.setState({});
                    }}/>
                </div>
            }
        }

        return (
            <Card fluid className={"card-shadow bottom-card-padding"}>
                {textToShow.content.length > 0 ?
                    <Image src={textToShow.content} wrapped ui={false}/>
                    : null
                }
                {editDiv}
                <Card.Content>
                    <Card.Meta className={"card-extra-break"}>
                        <EditSimpleUpperTextComponent
                            textStore={this.props.textStore} id={metaText}
                            namespace={this.props.namespace}/>
                    </Card.Meta>
                    <Card.Header className={"card-header"}>
                        <h3>
                            <EditTextComponent textStore={this.props.textStore} id={nameText}
                                               namespace={this.props.namespace}/>
                        </h3>
                    </Card.Header>
                </Card.Content>
            </Card>);
    }
}

CardImageMetaTitleComponent.propTypes = {
    textStore: PropTypes.object,
    namespace: PropTypes.string,
    metaText: PropTypes.string,
    nameText: PropTypes.string,
    imageLink: PropTypes.string,
};

CardImageMetaTitleComponent.defaultProps = {
    textStore: {},
    namespace: null,
    metaText: null,
    nameText: null,
    imageLink: null,
};

export default CardImageMetaTitleComponent
